import { activeArray } from '@/utils/localStorage.js'

// 新增表单配置
let addForm = [
  {
    showbutton: true,
    slotArr: ['apiTree'],
    items: {
      accessTokenTime: {
        label: '授权有效期', // 0代表永久
        type: 'datetime',
        style: 'datetime',
        defaultTime: '23:59:59',
        placeholder: '请选择',
        rules: [
          { type: 'date', required: true, message: '请选择授权有效期', trigger: 'change' }
        ],
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() < Date.now()
          }
        }
      },
      remark: {
        label: '授权备注',
        type: 'text',
        clearable: true,
        placeholder: '请输入授权备注'
      },
      apiTree: {
        label: '授权配置',
        type: 'slot',
        name: 'apiTree'
      }
    }
  }
]

// 编辑表单配置
let editForm = [
  {
    showbutton: true,
    slotArr: ['apiTree'],
    items: {
      remark: {
        label: '授权备注',
        type: 'text',
        clearable: true,
        placeholder: '请输入授权备注'
      },
      apiTree: {
        label: '授权配置',
        type: 'slot',
        name: 'apiTree'
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: 'API授权列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'el-icon-plus',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/api/auth/save-api-token')
      },
      clickEvent: (thisVue) => {
        thisVue.dialogForm = thisVue.tableObj.addForm
        thisVue.dialogValue = {}
        thisVue.dialogTitle = '新增授权'
        thisVue.getClearNode()
        // 清除点编辑之后清空表单出现的校验提示
        if (thisVue.$refs.addForm) {
          thisVue.$refs.addForm.$refs.form.resetFields()
        }
        thisVue.$set(thisVue, 'dialogShow', true)
      }
    }
  ]
}

// 列表columns配置
let columnsData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '授权凭证',
    prop: 'accessToken',
    show: true,
    enableSlot: true,
    minWidth: 120
  },
  {
    label: '授权有效期',
    prop: 'accessTokenTime',
    show: true,
    enableSlot: true,
    width: 200

  },
  {
    label: '创建时间',
    prop: 'createTime',
    show: true,
    enableSlot: true,
    width: 200
  },
  {
    label: '授权备注',
    prop: 'remark',
    show: true,
    enableSlot: true,
    width: 200
  },
  {
    label: '操作',
    prop: 'operators',
    width: 180,
    align: 'right',
    fixed: 'right',
    children: {
      edit: {
        label: '编辑',
        icon: 'ym-icon-bianji',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/api/auth/update-api-token-detail')
        },
        clickEvent: (thisVue, row) => {
          thisVue.dialogForm = thisVue.tableObj.editForm
          thisVue.dialogValue = row
          thisVue.dialogTitle = '编辑授权'
          thisVue.getPermissionsList(row)
          thisVue.$set(thisVue, 'dialogShow', true)
        }
      },
      start: {
        label: '启用',
        icon: 'el-icon-video-play',
        color: '#67C23A',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          if (activeArray('/api/auth/start-disable-api-token-(\\d+)-(\\d+)') && row.deleteFlag === 2) {
            return true
          }
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleStart(row)
        }
      },
      stop: {
        label: '停用',
        icon: 'el-icon-video-pause',
        color: '#E6A23C',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          if (activeArray('/api/auth/start-disable-api-token-(\\d+)-(\\d+)') && row.deleteFlag === 1) {
            return true
          }
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleStop(row)
        }
      }
    }
  }
]

// 分页配置项
let pageData = {
  size: 15, // 每页的数据量
  pageSize: 15,
  total: 0, // 数据总量
  layout: 'prev, pager, next, total, jumper, slot',
  currentPage: 1 // 当前页
}

let tableObj = {
  'columnsData': columnsData, // 表格列/操作列配置
  'headData': headData, // 表头图标配置
  'addForm': addForm, // 新增表单配置
  'editForm': editForm, // 编辑表单配置
  'pageData': pageData // 分页数据
}

export default tableObj
