<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form"
                 @search="search"></search-form>
    <!-- 列表组件 -->
    <div class="table-group">
      <ym-table-list :headData="headData"
                     :data="tableData"
                     :columns="columns"
                     :pageData="pageData"
                     :tableOption="{size: 'mini'}"
                     :loading="loading"
                     fit
                     stripe
                     @handlePageChange="handlePageChange"
                     @handleSizeChange="handleSizeChange"
                     row-key="id">
        <!-- 授权有效期 -->
        <template #accessToken="{row}">
          <div class="div-row">
            <popover :width="500"
                   :value="row.accessToken"
                   :isSubStr="false"
                   :showDict="false"
                   :divClass="'shenglue-1'"></popover>
            <i class="el-icon-document-copy" style="margin-left: 10px"
               @click="copy($event, row.accessToken)"></i>
          </div>
        </template>
        <!-- 授权有效期 -->
        <template #accessTokenTime="{row}">
          <span v-if="row.accessTokenTime !== 0">
            {{ tf(row.accessTokenTime, 'YYYY-MM-DD HH:mm:ss') }}
          </span>
          <span v-else>
            永久
          </span>
        </template>
        <!-- 创建时间 -->
        <template #createTime="{row}">
          {{ tf(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
        <!-- 授权备注 -->
        <template #remark="{row}">
          <popover v-if="row.remark" :width="300"
                   :value="row.remark"
                   :isSubStr="false"
                   :showDict="false"
                   :divClass="'shenglue-1'"></popover>
          <div v-else>无</div>
        </template>
        <!-- 是否启用 -->
        <!-- <template #deleteFlag="{row}">
          <el-button v-if="row.deleteFlag === 2" plain size="mini"
                     type="primary" @click="handleStart(row)">启用</el-button>
          <el-button v-else-if="row.deleteFlag === 1" plain size="mini"
                     type="danger" @click="handleStop(row)">禁用</el-button>
        </template> -->
      </ym-table-list>
    </div>

    <!-- 新增表单 -->
    <el-dialog class="tableList-addForm" :close-on-click-modal="false"
               width="800px"
               :title="dialogTitle"
               :visible.sync="dialogShow">
      <ymForm label-position="left"
              ref="addForm"
              refCode="addForm"
              :formValue="dialogValue"
              :formData="dialogForm"
              @onSubmit="onSubmit">
        <template #apiTree>
          <el-tree :data="datatree"
                   show-checkbox
                   node-key="controlID"
                   ref="tree"
                   :props="defaultProps">
            <span class="custom-tree-node"
                  slot-scope="{ node, data }">
              <span :title="node.name">{{ data.title }}</span>
            </span>
          </el-tree>
        </template>
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button v-show="dialogForm[0]&&dialogForm[0].showbutton"
                   size="medium"
                   @click="submitForm('addForm')"
                   type="primary"
                   v-loading="isSubmitting">提交 </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Clipboard from 'clipboard'
import YmTableList from '@/components/TableList/index.vue'
import ymForm from '@/components/YmForm'
import Popover from '@/components/Popover'
import SearchForm from './component/SearchForm.vue'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from './component/data.js'
export default {
  components: {
    YmTableList,
    ymForm,
    SearchForm,
    Popover
  },
  data() {
    return {
      // 表单
      form: {
        accessToken: null,
        deleteFlag: null, // 授权状态（默认全部）
        startList: [],
        endList: []
      },
      startLoading: false,
      copyLoading: false,
      isSubmitting: false,
      loading: false,
      tableData: [],
      formValue: {},
      dialogTitle: '',
      dialogShow: false,
      dialogValue: {},
      dialogForm: [],
      tableObj: tableObj,
      columns: tableObj.columnsData,
      addForm: tableObj.addForm,
      headData: tableObj.headData,
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      },
      datatree: [],
      defaultProps: {
        children: 'child',
        label: 'label'
      },
      permissionsList: []
    }
  },
  mounted() {
    this.getData(1)
    this.listControls()
  },
  methods: {
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      this.getData(1)
    },
    // 一键复制
    copy(e, text) {
      console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    },
    // 启用授权
    async handleStart(row) {
      let options = {
        apiTokenId: row.apiTokenId,
        deleteFlag: 1
      }
      const { code } = await this.$api.rbac.changeApi(options)
      if (code === 200) {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message.success('启用成功！')
        this.getData(1)
      }
    },
    // 停用授权
    async handleStop(row) {
      let options = {
        apiTokenId: row.apiTokenId,
        deleteFlag: 2
      }
      const { code } = await this.$api.rbac.changeApi(options)
      if (code === 200) {
        this.getData(1)
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message.success('停用成功!')
      }
    },
    // 获取权限列表
    async listControls() {
      const { data, code } = await this.$api.rbac.listApi()
      if (code === 200) {
        // 处理树结构
        var tree = []
        for (let node of data) {
          var array = []
          for (let leaf of data) {
            if (node.controlID === leaf.parentID) {
              array.push(leaf)
            }
          }
          if (array.length !== 0) {
            node.child = array
            if (!node.parentID) {
              tree.push(node)
              // console.log('data:', tree)
              this.datatree = tree
            }
          }
        }
      }
    },
    // 获取编辑授权明细
    async getPermissionsList(row) {
      let options = {
        apiTokenId: row.apiTokenId
      }
      const { data, code } = await this.$api.rbac.detailApi(options)
      this.$refs.tree.setCheckedKeys([])
      if (code === 200) {
        data.forEach((i, n) => {
          if (i.authFlag === 1) {
            var node = this.$refs.tree.getNode(i)
            // console.log(i, node)
            if (node) {
              if (node.isLeaf) {
                this.$refs.tree.setChecked(node, true)
              }
            }
          }
        })
      }
    },
    // 清空树选项
    getClearNode() {
      if (this.$refs.tree) {
        this.$refs.tree.setCheckedKeys([])
      }
    },
    // 获取表格数据
    async getData(currentPage, name) {
      this.loading = true
      let options = {
        page: currentPage - 1,
        size: this.pageData.size,
        accessToken: this.form.accessToken,
        deleteFlag: this.form.deleteFlag,
        startCreateTime: Date.parse(this.form.startList[0]) || null,
        endCreateTime: Date.parse(this.form.startList[1]) || null,
        startAccessTokenTime: Date.parse(this.form.endList[0]) || null,
        endAccessTokenTime: Date.parse(this.form.endList[1]) || null
      }
      let { data, code } = await this.$api.rbac.listApiToken(options)
      if (code === 200) {
        this.tableData = data.content
        this.pageData.total = data.total
        // console.log('tableData:', this.tableData)
      }
      this.loading = false
    },
    // 重置表单
    resetForm(key) {
      this.$set(this, key, {})
      this.getData(1)
    },
    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    async onSubmit(form, ref) {
      console.log(form, ref)
      if (this.isSubmitting) {
        return
      }
      if (ref === 'addForm') {
        if (!form.apiTokenId) {
          // 添加
          let time = new Date(form.accessTokenTime)
          let accessTokenTime = time.getTime(time)
          let treeList = this.$refs.tree
            .getHalfCheckedNodes()
            .concat(this.$refs.tree.getCheckedNodes())
          var controlIDs = []
          for (let item of treeList) {
            controlIDs.push(item.controlID)
          }
          // 添加授权
          let options = {
            accessTokenTime: accessTokenTime,
            controlIDs: controlIDs,
            remark: form.remark || null
          }
          this.isSubmitting = true
          let { code, message } = await this.$api.rbac.addApi(options)
          this.isSubmitting = false
          if (code === 200) {
            this.dialogShow = false
            this.dialogValue = {}
            this.$message.success('新增授权成功！')
            this.getData(1)
          } else {
            this.$message.error(message + '!')
          }
          setTimeout(() => {
            this.isSubmitting = false
          }, 1000)
        } else {
          let treeList = this.$refs.tree
            .getHalfCheckedNodes()
            .concat(this.$refs.tree.getCheckedNodes())
          var apiTokenDetails = []
          for (let item of treeList) {
            var obj = {}
            if (item.apiTokenDetailId) {
              obj.apiTokenDetailId = item.apiTokenDetailId
            }
            obj.controlID = item.controlID
            apiTokenDetails.push(obj)
          }
          // 修改授权
          let options = {
            apiTokenDetails: apiTokenDetails,
            apiTokenId: form.apiTokenId,
            remark: form.remark || null
          }
          this.isSubmitting = true
          let { code } = await this.$api.rbac.updateApi(options)
          if (code === 200) {
            this.dialogShow = false
            this.dialogValue = {}
            this.$message.success('修改授权成功！')
            this.getData(1)
          }
          setTimeout(() => {
            this.isSubmitting = false
          }, 1000)
        }
      }
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    }
  },
  filters: {
    cnStatus(value) {
      return value === 1 ? '正常' : '拉黑'
    }
  }
}
</script>

<style lang="scss" scoped>
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tableList-form {
  background: #fff;
  border-radius: $--border-radius-base;
  padding: 20px 20px 0;
  margin-bottom: 12px;
  border: $--border-width-base $--border-style-base $--border-color-light;
  width: 100%;
  overflow: hidden;

  /deep/.ym-form {
    float: left;
  }

  .tableList-form-buttonBox {
    float: left;
    button {
      letter-spacing: 4px;
    }
    .el-button--text {
      letter-spacing: 0;
      margin-left: 12px;
      i {
        font-size: $--font-size-extra-small;
        color: $--color-primary;
        margin: 2px;
      }
    }
  }
}
.tableList-addForm {
  /deep/.el-dialog {
    max-width: 600px;
    .el-dialog__body {
      padding: 30px 80px 50px;
      .tableList-form-buttonBox {
        text-align: center;
      }
    }
    .passwordContent {
      margin: 20px 0 50px 0;
    }
    .el-input {
      width: 85% !important;
    }
    .el-tree {
      width: 85% !important;
      border: 1px solid $--border-color-base;
      border-radius: 5px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .el-tree-node__content {
      border-radius: 5px;
    }
  }
}
/deep/.tableList-addForm .el-dialog .el-dialog__body .tableList-form-buttonBox {
  text-align: right;
  margin-top: 16px;
}
</style>
