<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-position="right"
             label-width="120px">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="授权凭证">
            <el-input class="input"
                      clearable
                      @keyup.enter.native="confirm"
                      v-model="form.accessToken"
                      placeholder="授权凭证"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="授权状态">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.deleteFlag">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="创建时间范围">
            <el-date-picker class="inputWid"
                            v-model="form.startList"
                            type="datetimerange"
                            :picker-options="pickerOptions"
                            range-separator="-"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="结束时间范围">
            <el-date-picker class="inputWid"
                            v-model="form.endList"
                            type="datetimerange"
                            :picker-options="pickerOptions"
                            range-separator="-"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="16"
                :lg="16"
                :xl="16">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [
        {
          label: '全部',
          value: null
        },
        {
          label: '已启用',
          value: 1
        },
        {
          label: '已停用',
          value: 2
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    rest() {
      this.form.accessToken = null
      this.form.deleteFlag = null
      this.form.startList = []
      this.form.endList = []
      this.confirm()
    },
    confirm() {
      let _form = {
        accessToken: this.form.accessToken ? this.trim(this.form.accessToken) : null,
        deleteFlag: this.form.deleteFlag,
        startList: this.form.startList || [],
        endList: this.form.endList || []
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
