var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ym-page"},[_c('search-form',{attrs:{"form":_vm.form},on:{"search":_vm.search}}),_c('div',{staticClass:"table-group"},[_c('ym-table-list',{attrs:{"headData":_vm.headData,"data":_vm.tableData,"columns":_vm.columns,"pageData":_vm.pageData,"tableOption":{size: 'mini'},"loading":_vm.loading,"fit":"","stripe":"","row-key":"id"},on:{"handlePageChange":_vm.handlePageChange,"handleSizeChange":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"accessToken",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"value":row.accessToken,"isSubStr":false,"showDict":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.copy($event, row.accessToken)}}})],1)]}},{key:"accessTokenTime",fn:function(ref){
var row = ref.row;
return [(row.accessTokenTime !== 0)?_c('span',[_vm._v(" "+_vm._s(_vm.tf(row.accessTokenTime, 'YYYY-MM-DD HH:mm:ss'))+" ")]):_c('span',[_vm._v(" 永久 ")])]}},{key:"createTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.createTime, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"remark",fn:function(ref){
var row = ref.row;
return [(row.remark)?_c('popover',{attrs:{"width":300,"value":row.remark,"isSubStr":false,"showDict":false,"divClass":'shenglue-1'}}):_c('div',[_vm._v("无")])]}}])})],1),_c('el-dialog',{staticClass:"tableList-addForm",attrs:{"close-on-click-modal":false,"width":"800px","title":_vm.dialogTitle,"visible":_vm.dialogShow},on:{"update:visible":function($event){_vm.dialogShow=$event}}},[_c('ymForm',{ref:"addForm",attrs:{"label-position":"left","refCode":"addForm","formValue":_vm.dialogValue,"formData":_vm.dialogForm},on:{"onSubmit":_vm.onSubmit},scopedSlots:_vm._u([{key:"apiTree",fn:function(){return [_c('el-tree',{ref:"tree",attrs:{"data":_vm.datatree,"show-checkbox":"","node-key":"controlID","props":_vm.defaultProps},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',{attrs:{"title":node.name}},[_vm._v(_vm._s(data.title))])])}}])})]},proxy:true}])}),_c('div',{staticClass:"tableList-form-buttonBox"},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogForm[0]&&_vm.dialogForm[0].showbutton),expression:"dialogForm[0]&&dialogForm[0].showbutton"},{name:"loading",rawName:"v-loading",value:(_vm.isSubmitting),expression:"isSubmitting"}],attrs:{"size":"medium","type":"primary"},on:{"click":function($event){return _vm.submitForm('addForm')}}},[_vm._v("提交 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }